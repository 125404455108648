.tablist-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: flex-start;
}
.main-div {
  height: 100vh;
  overflow: hidden;
}

.editor-header {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: var(--xpub-secondary-color);
  color: var(--text-color-secondary);
  // border-bottom: 1px solid var(--text-color-secondary);
}

.editor-header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  background-color: var(--xpub-secondary-color);
  color: var(--text-color-secondary);
}

.editor-title {
  font-size: var(--text-large);
  font-weight: 300;
  color: var(--text-color-secondary) !important;
}

.home-button {
  fill: var(--text-color-[secondary]);
  height: 1.1rem;
  width: 1.1rem;
  cursor: pointer;
}

.credits {
  display: flex;
  flex-direction: row;
  // color: white;
  color: var(--text-color-secondary);
  align-items: center;
  justify-content: center;
  font-size: var(--text-medium) !important;
  margin: 0.1rem;
  gap: 0.3rem;
  cursor: pointer;
}

.credit-icon {
  fill: var(--xpub-accent-color-2);
}

.credits-value {
  display: center;
}

.tabs-wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-right: 40px;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  height: 100%;
  background-color: white;
}

.tabs-wrapper::-webkit-scrollbar {
  display: none; // Chrome, Safari, Opera
}

.tab-list {
  display: flex;
  min-height: 2rem;
}

.tab {
  flex: 0 0 auto;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  background-color: #e0e0e0;

  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #d4d4d4;
  }

  &.active {
    background-color: white; // White background for the active tab
    color: #000; // Ensure the text color is appropriate for the white background
  }
}

.left-column {
  flex: 0 0 auto;
  margin-right: 0% !important;
}

.editor-background {
  background-color: var(--xpub-secondary-color);
  // padding-right: 5rem !important;
}

.right-column {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-left: 0% !important;
  padding-right: 0% !important;
  box-sizing: border-box;
  // height: 100vh;
  position: relative;
  border-top: 2px solid var(--contrast-color-secondary) !important;
  border-left: 2px solid var(--contrast-color-secondary) !important;
  border-right: 2px solid var(--contrast-color-secondary) !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

.override-margins {
  margin: 0 !important;
  padding: 0 !important;
}

.flex-vertical-container {
  display: flex;
  flex-direction: column;
}

.flex-horizontal-container {
  display: flex;
  flex-direction: row;
}

.fill-flex {
  flex: 1 0 auto;
}

.fix-flex {
  flex: 0 0 auto;
}

.nav-bar {
  min-height: 4rem;
  flex: 0 0 auto;
}

.not-nav-bar {
  flex: 1 0 auto;
}

.no-padding {
  margin: 0% !important;
  padding: 0 !important;
}

.content-padding {
  margin-top: 2% !important;
  padding: 0 !important;
}

.tab-panel {
  padding-top: 0% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
  padding-bottom: 0% !important;
  background-color: white;
}

.custom-tab {
  display: flex;
  max-width: 100%;
  height: 100%;
  width: 150px;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;

  .tab-name {
    display: flex;
    width: 90%;

    .tab-label {
      display: flex;
      align-items: center;
      margin-left: 1%;
      // margin-right: 1rem;
      // padding: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // height: 100%;
      // width: 90%;
    }
  }

  .close-button {
    color: gray;
    margin-left: 5%;
    width: 10%;
  }
}

button.cds--tabs__nav-item.cds--tabs__nav-link.tab {
  border-right: 1px solid rgba(128, 128, 128, 0.5);
  margin: 0;
}

.active-tab-label {
  display: flex;
  align-items: center;
  margin-left: 1%;
  margin-right: 2%;
  padding: 5px;
  width: 100%;
  min-height: 100%;
  white-space: nowrap;
}

.add-tab-container {
  height: 100%;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}

.add-tab-button {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  margin-right: 30%;
}

.loading-indicator {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 2px;
  margin: 0 2px;
  animation: loadingAnimation 1s infinite;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
  margin-right: 10px;
}

@keyframes loadingAnimation {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.tab-list {
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

.tabs-wrapper::-webkit-scrollbar {
  display: none;
}

@media (max-width: 600px) {
  .add-tab-container {
    position: sticky;
    right: 16px;
    bottom: 1rem;
    margin-left: auto;
    z-index: 1;
  }
}

.fact-check-grid {
  background-color: white;
}

// .cds--tabs .cds--tabs__nav-item--selected {
//   border-bottom: 2px solid var(--button-primary) !important;
// }

// .cds--loading__stroke {
//   stroke: var(--xpub-brand-color);
// }

// .cds--btn--primary {
//   // background-color: var(--xpub-brand-color);

//   &:focus {
//     border-color: var(--xpub-brand-color);
//     box-shadow: inset 0 0 0 1px #ffffff;
//   }

//   &:hover {
//     background-color: #5a3887;
//   }
// }

// .cds--btn-set .cds--btn {
//   &:focus {
//     border-color: var(--xpub-brand-color);
//     box-shadow: inset 0 0 0 1px #ffffff;
//   }
// }

// .cds--text-input {
//   &:focus {
//     outline-color: var(--xpub-brand-color) !important;
//   }
// }

// .cds--select-input {
//   border-radius: 1rem !important;
//   &:focus {
//     outline-color: var(--xpub-brand-color) !important;
//   }
// }

// .cds--btn--secondry {
//   &:focus {
//     border-color: var(--xpub-brand-color);
//     box-shadow: inset 0 0 0 1px #ffffff;
//   }
// }

// .cds--slider-text-input {
//   inline-size: 5rem !important;
// }

// .cds--slider-text-input:read-only {
//   pointer-events: none;
// }
