.e-pv-toolbar {
  height: 3rem;
}

.custom-tooltip {
  .e-tooltip-wrap {
    // Add styles to match the default tooltip
    background-color: #616161;
    border-color: #616161;
    color: #ffffff;
  }
}