.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1000;
}

.profile-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;
}

.profile-menu-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.profile-menu-item svg {
  margin-right: 10px;
}

.graph-file-browser-container {
  display: flex;
  width: 50rem; /* Cover 70% of the page width */
  height: 40rem; /* Cover 70% of the page height */
  margin: 0 auto; /* Center horizontally */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center vertically */
}

.css-55 {
  justify-content: flex-end !important;
}

// Hide New and upload
.ms-OverflowSet.root_8ddbc6c5.primarySet-44 {
  display: none !important;
}

.ms-OverflowSet.root_8ddbc6c5.secondarySet-45 {
  margin-left: 100% !important;
}

.ms-Fabric.root-42 {
  min-width: 50rem;
}
