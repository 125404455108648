/* StripePricing.scss */

.pricing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.2rem 2rem;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.plans-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.plan-box {
  border: 1px solid var(--xpub-secondary-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  width: 30%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.plan-box h2 {
  margin-top: 0;
}

.plan-box p {
  font-size: var(--text-medium);
  font-weight: 450;
}

.feature-list {
  text-align: left;
  padding-left: 1rem;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin: 0.3rem 0;
  }
}

.checkmark-icon {
  margin-right: 1rem;
  color: var(--xpub-accent-color-2);
}

.feature-list {
  margin: 1rem 0;
}
