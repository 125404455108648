$primary-color: var(--button-primary);
@import 'loaders.css/src/animations/ball-grid-pulse.scss';


.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}


.containerFlex {
  display: flex;
}

.columnFlex:first-child {
  flex: 0 0 auto; /* This will make it only as wide as necessary */
}

.columnFlex:last-child {
  flex: 1; /* This will make it take the remaining space */
}

.archive-search .cds--search-magnifier {
  display: none;
}

.archive-search .cds--search-close {
  display: none;
}

.archive-button .cds--btn--primary {
  background: none;
  color: black !important;
  fill: black !important;
  padding: 10px !important;
  width: 0 !important;
  border: none !important;
  &:hover {
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.cds--search-input {
  &:focus {
    outline-color: var(--xpub-brand-color) !important;
  }
}

.sources .cds--accordion__heading::before {
  background-color: unset;
  content: none;
  border: none !important;
  outline: none;
}

.sources .cds--accordion__heading:focus {
  border: 1.5px solid var(--xpub-brand-color) !important;
}
.sources .cds--accordion__title {
  font-weight: 500;
  &:focus {
    border: none !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.player-wrapper {
  position: relative;
  padding-top: 40%;
  height: 0;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
