.left-panel {
  height: 100vh;
  width: auto;
  position: fixed;
  left: 0;
  padding: 0rem 0.5rem;
  background: linear-gradient(
    225deg,
    var(--xpub-brand-color),
    color-mix(in srgb, var(--xpub-brand-color) 60%, black)
  );

  .logo-container {
    height: 5rem;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    // padding-left: 0.7rem;
    position: relative;
    left: 0;
    top: 0rem;
    z-index: 1;
    align-items: center;
    justify-content: left;
  }

  .sidebar-logo {
    height: 3.5rem;
    width: auto;
    padding-top: 1.3rem;
    right: 0.6rem;
    position: relative;
    z-index: 0;
  }

  .sidebar-x {
    height: 2.7rem;
    width: auto;
    padding-left: 0.8rem;    
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .menu-item {
      display: flex;
      position: relative;
      width: 100%;
      align-items: center;
      // justify-content: center;
      padding: 0.5rem 0.8rem;
      margin: 0.5rem 0;
      text-decoration: none;
      color: var(--text-color-primary);
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      }

      &:not(.disabled):hover {
        transition: background-color 0.3s ease;
        background-color: var(--element-hover-color);
        border-radius: var(--border-radius);
      }

      &.active {
        background-color: var(--element-hover-color);
        border-radius: var(--border-radius);

        svg {
          border-radius: 50%;
          background-color: var(--element-hover-color);
          flex-shrink: 0;
        }
      }

      svg {
        width: 1.5em;
        height: 1.5em;
        flex-shrink: 0;
      }

      .menu-text {
        margin-left: 0.5rem;
        display: flex; /* Ensures flexbox layout */
        flex-wrap: nowrap; /* Prevents wrapping of flex items */
        white-space: nowrap; /* Prevents text from wrapping */
        overflow: hidden; /* Hides any overflow content */
        text-overflow: ellipsis; /* Adds ellipsis to indicate truncated text */
        font-size: var(--text-medium);
      }

      .coming-soon-tag,
      .beta-tag {
        position: absolute;
        top: 0.5rem;
        right: 0.3rem;
        background-color: var(--contrast-color-primary);
        color: var(--text-color-secondary);
        border-radius: var(--border-radius);
        font-size: var(--text-small);
        padding: 0.2rem 0.4rem;
      }
    }
  }

  .bottom-section {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    .profile-section {
      margin-top: 2rem;
      padding: 0 0.5rem;

      cursor: pointer;

      .profile {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 0.9rem;
        transition: background-color 0.3s ease;
        gap: 0.8rem;
        &:hover {
          background-color: var(--element-hover-color);
          border-radius: var(--border-radius);
          // margin: 1rem;
        }

        .profile-initials {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          object-fit: cover;
          background-color: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          font-weight: bold;
        }

        .user-email {
          color: white;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: var(--text-medium);
        }
      }

      .dropdown-menu {
        display: block;
        position: absolute;
        top: -50%;
        right: 0;
        margin-right: 1rem;
        width: 10rem;
        background-color: var(--text-color-primary);
        border: 1px solid var(--text-color-primary);
        box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
        z-index: 1009999;
        border-radius: var(--border-radius);
        overflow: hidden;
        padding: 0.5rem;

        span {
          text-decoration: none;
          color: var(--element-hover-color);
        }
      }

      .profile-menu-title {
        font-size: var(--text-small);
        color: var(--element-hover-color);
        padding: 0.5rem;
      }

      .profile-menu-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-decoration: none;
        color: var(--element-hover-color);
        font-size: var(--text-medium);
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;

        &:hover {
          background-color: var(--contrast-color-primary);
          border-radius: var(--border-radius);
        }
      }
    }
  }

  @media (min-width: 2000px) {
    &:hover {
      width: 11rem;
    }
  }
}
