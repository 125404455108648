.bx--tabs__nav {
  border-bottom: 1px solid #444;
  margin-bottom: 1rem;
}

.profile-settings,
.archives-settings,
.billing-settings {
  padding: 1rem;
  border-radius: 0.5rem;

  h2 {
    font-size: var(--text-large);
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;

    .bx--form-item {
      margin-bottom: 1rem;
    }
  }

  .current-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    p {
      margin: 0;
      span {
        font-weight: bold;
        color: var(--xpub-accent-color-2);
        text-transform: uppercase;
      }
    }
  }
}

.profile-settings {
  .account-info {
    padding: 1rem;
    p {
      display: flex;

      strong {
        width: 100%;
      }
    }

    .edit-icon {
      cursor: pointer;
    }
  }
}
