.rfp-side-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 3rem);
  // background-color: var(--contrast-color-primary);
  // border-right: 1px solid var(--xpub-brand-color-border);
  background-color: var(--xpub-secondary-color);
  width: 100%;
  // padding: 0.5rem 0.5rem;
}

.answer-controls {
  display: flex;
  right: 0;
}

.sources {
  height: 32vh;
  padding: 0.5rem;
  overflow-y: auto;
}

.answer-box {
  margin: 0rem 0.3rem 0.3rem 0.3rem;
  border: 0.1rem solid rgb(221, 219, 219);
  height: calc(100vh - 3.5rem);
  background-color: white;
  border-radius: var(--border-radius);
}

.typography-content h1 {
  font-size: 1.25em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.typography-content h2 {
  font-size: 1.1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.typography-content h3 {
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.typography-content h4 {
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.typography-content p {
  font-size: 0.8rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.css-mtwpo0-RichTextContent-root-RichTextContent-readonly .ProseMirror {
  height: 80.2vh;
  padding: 1rem;
  overflow-y: auto;
}

.sc-imWZod.hxiLpm {
  display: none !important;
}