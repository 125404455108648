.logo {
  margin-top: 10%;
}

.heading {
  margin: 3% 0 2% 0;
}

.ul-style {
  list-style: disc;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  text-align: left;
}

.left {
  // background-color: var(--xpub-brand-color);
  background: linear-gradient(
    225deg,
    var(--xpub-brand-color),
    color-mix(in srgb, var(--xpub-brand-color) 80%, black)
  );
}

.custom-inline-notification {
  .cds--inline-notification__title,
  .cds--inline-notification__subtitle {
    color: white !important;
  }
}

.span-privacy {
  font-size: var(--text-medium);
}

.loginButton.cds--btn.cds--btn--primary {
  border-radius: 3rem !important;
  padding-left: 8%;
  padding-right: 8%;
}

/* Login.scss */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-header {
  font-size: var(--text-extra-large);
  font-weight: 500 !important;
  background-color: #212221;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding: 2rem;
}

.auth-full-width {
  width: 70%;
}
