.clickable-cms-tile {
  height: 15rem;
  width: 20rem;
  background-color: var(--xpub-brand-color);
  cursor: pointer;
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  color: var(--text-color-primary);

  &:hover {
    background-color: var(--element-hover-color);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
    pointer-events: none;
  }
}
