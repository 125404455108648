.dataTable {
  margin-top: 20%;
}

.addProject {
  z-index: 1;
}

.toolbar-text {
  margin-left: 0.5%;
}

// .create-project-button {
//   border-radius: 1rem 1rem 0rem 0rem !important;
//   // justify-content: start !important;
//   // align-items: center;
//   // gap: 0.5rem;
//   // padding: 0rem 3rem;
// }